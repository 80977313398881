:root {
    --app-backgroundColor: #f8f8f8;
    --app-headerBackgroundColor: #f8f8f8;
    --app-footerBackgroundColor: #f8f8f8;
    --app-priamry: #006bb6;
    --app-gradientBackgroundColor: #6ab1e3;
    --app-cardFontColor: #fff;
    --app-fontColor: #006bb6;
    --app-h1: #006bb6;
    --app-h2: #006bb6;
    --app-h3: #006bb6;
    --app-h4: #006bb6;
    --app-h5: #006bb6;
    --app-pageTitle: #006bb6;
    --app-menuIcon: #fff;
    --app-body1: #006bb6;

}