 
.line
{
  background-color: aqua;
  box-shadow: 0px 0px 12px aqua;
  margin: 0 auto;
  margin-left: 30px;
  width: 2px;
  height: 90%;
  border-radius: 50%;
  overflow: hidden;
}

.line .scanner 
{
  top: 0;
  left: 0;
  background: white;
  width: 2px;
  height: 60%;
  border-radius: 50%;
  animation: scanner-loop 3s ease-in-out infinite;
}


@keyframes scanner-loop
{
  0% 
  {
    top: 0;
  }
  50%
  {
    top: 100%;
  }
  100%
  {
    top: 0;
  }
}


/* Floating */
.floating {
  animation-name: floating;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin: 0 auto;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

// loading
.loadingContainer {
  width: 75%;
  margin: 0 auto;
  align-items: center;
  text-align: center;
  padding: 10px;
  z-index: 1;
  border-radius: 25px;
}

.loading span {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  margin: 0.19em;
  background: #007db6;
  border-radius: 0.6em;
  animation: loading 1s infinite alternate;
}

.loading h2 {
  color: $primary;
  font-size: 16px;
}

.loading span:nth-of-type(2) {
  background: #016bb5;
  animation-delay: 0.2s;
}
.loading span:nth-of-type(3) {
  background: #1c91e6;
  animation-delay: 0.4s;
}
.loading span:nth-of-type(4) {
  background: #52b5fc;
  animation-delay: 0.6s;
}
.loading span:nth-of-type(5) {
  background: #82cbff;
  animation-delay: 0.8s;
}
.loading span:nth-of-type(6) {
  background: #82cbff;
  animation-delay: 1s;
}
.loading span:nth-of-type(7) {
  background: #aafbf2;
  animation-delay: 1.2s;
}
@keyframes loading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


/* Circle Flasher Effect */

.circleFlasher {
  border-radius: 100%;
  position: absolute;
  width: 5px;
  margin: 30px 0 0 5px;
  height: 5px;
  background: aqua;
  animation: circleFlasherPulse 1s ease infinite alternate;
}

@keyframes circleFlasherPulse {
  0%,
  100% {
    background-color: aqua;
  }

  25% {
    background-color: rgb(83, 255, 255);
  }

  50% {
    background-color:rgb(170, 255, 255);
  }

  75% {
    background-color: rgb(224, 255, 255);
  }
}

.circleFlasher2 {
  border-radius: 100%;
  position: absolute;
  width: 4px;
  margin: 33x 0 0 90px;
  height: 4px;
  background: #bb8f1f;
  animation: circleFlasherPulse2 1s ease infinite alternate;
}

@keyframes circleFlasherPulse2 {
  0%,
  100% {
    background-color: #bb8f1f;
  }

  25% {
    background-color: rgb(211, 175, 84);
  }

  50% {
    background-color: #ffe7a9;
  }

  75% {
    background-color: #fff4d7;
  }
}

.circleFlasher3 {
  border-radius: 100%;
  position: absolute;
  width: 4px;
  margin: 90px 0 0 65px;
  height: 4px;
  background: #fdcb4c;
  animation: circleFlasherPulse3 1s ease infinite alternate;
}

@keyframes circleFlasherPulse3 {
  0%,
  100% {
    background-color: #bb8f1f;
  }

  25% {
    background-color: rgb(211, 175, 84);
  }

  50% {
    background-color: #ffe7a9;
  }

  75% {
    background-color: #fff4d7;
  }
}

/* Fade Animation */

.fadePanel {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Typing */


.css-typing p {
  border-right: 0.2em solid $primary;
  font-family: Consolas, monospace;
  font-size: 19px;
  white-space: nowrap;
  overflow: hidden;
  color: #64696f;
  margin-top:15px;
 
}
.css-typing p:nth-child(1) {
  width: 7.3em;
  -webkit-animation: type 3s steps(12, end);
  animation: type 2s steps(12, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid $primary;
  }
  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid $primary;
  }
  100% {
    border: none;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    border-color: tranparent;
  }
}

.typing-demo {
  width: 30ch;
  animation: typing 3s steps(30), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: Consolas, monospace;
  font-size: 19px;
  color: #64696f;

}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes removeTyping {
  from {
    width: 100%;
  }
  90%,
  to {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

/* Space Gradient */
.css-selector {
  background: linear-gradient(257deg, #006bb6, $primary);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 5s ease infinite;
  -moz-animation: AnimationName 5s ease infinite;
  animation: AnimationName 5s ease infinite;
}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 70%}
  50%{background-position:100% 31%}
  100%{background-position:0% 70%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 70%}
  50%{background-position:100% 31%}
  100%{background-position:0% 70%}
}
@keyframes AnimationName {
  0%{background-position:0% 70%}
  50%{background-position:100% 31%}
  100%{background-position:0% 70%}
}


.snow {
	position: absolute;

	width: 100%;

}

.snow .svg {
	position: absolute;

	height:100px;
}

#snow-top-layer {
	will-change: transform;
	transform: translateY(-768px);
	animation: fall 22.5s infinite linear;
}

#snow-bottom-layer {
	will-change: transform;
	transform: translateY(-768px);
	animation: fall 45s infinite linear;
}

@keyframes fall {
	
	100% {
		transform: translateY(0);
	}
	
}

@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}

.multi-ripple {
  width: 3.6rem;
  height: 3.6rem;
  margin: 0 auto;

  div {
    position: absolute;
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    border: 0.3rem solid #53dfff;
    animation: 1.5s ripple infinite;
    text-align: center;
    &:nth-child(2) {
      animation-delay: 0.5s;
    }
  }
}


.container{
  display: inline-block;
  font-family: "arial";
  font-size: 24px;
 
}

.typed {
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  animation: typing;
  animation-duration: 1.5s;
  animation-timing-function: steps(30, end);
  animation-fill-mode: forwards;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}


/* Hover Effect */

.container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem;
}
// End Codepen spesific styling


.hoverEffect {
	display: flex;
	text-decoration: none;
	color: inherit;
	max-width: 100%;
	position: relative;
	cursor: pointer; 
	&:before {
		position: absolute; 
		left: 0;
		bottom: 0;
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		background-color: #0c122d;
		transform-origin: 0 bottom 0;
		transform: scaleY(0);
		transition: .4s ease-out;
	}
	
	&:hover {
    color: white;
    border-radius: 15px;
		.post-title {
			color: #FFF;
      border-radius: 15px;
		}
		&:before {
			transform: scaleY(1);
		}
	}
}

.post-title {
	position: relative;
	font-size: 2rem;
	font-weight: 700;
	line-height: 1.333;
	transition: .4s ease-out;
}


/* Hover Grid Box Effect */
.hoverGrid {
  --s: 90%;   /* the size of the image */
  --b: 2px;     /* the border thickness*/
  --g: 14px;    /* the gap */
  --c: #01518B; /* the color */
  
  width: var(--s);
  aspect-ratio: 1;
  outline: calc(var(--s)/2) solid #0009;
  outline-offset: calc(var(--s)/-2);
  cursor: pointer;
  transition: .3s;
}
.hoverGrid:hover {
  outline: var(--b) solid var(--c);
  outline-offset: var(--g);
}

.hoverGrid + .hoverGrid {
  border-radius: 25%;

}


/* Button Animation */
.gradient {
  background: linear-gradient(130deg, #2ba5fd, #52b7ff, #c1e5ff);
  background-size: 200% 200%;
  -webkit-animation: Animation 5s ease infinite;
  -moz-animation: Animation 5s ease infinite;
  animation: Animation 5s ease infinite;
}

.expandMoreGradient {
  background: linear-gradient(130deg, #2d4098, #5269ce, #8499f4);
  background-size: 200% 200%;
  -webkit-animation: Animation 2s ease infinite;
  -moz-animation: Animation 2s ease infinite;
  animation: Animation 2s ease infinite;
}

@-webkit-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@-moz-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}


  
  
  

  

