
@import "./variables.scss";

/* Button */
.MuiButton-outlined {
  border: $primary 2px solid;
  color: $primary;
}

.MuiButton-contained {
  background: $primary;
  color: $white;

}

/* Card Media */
.MuiCardMedia-img{
  width: 100px;
  text-align: center;

}

/* Spacer */
.spacer10 {
    height: 10px;
    background: transparent;
}

.spacer20 {
    height: 20px;
    background: transparent;
}

.spacer30 {
    height: 30px;
    background: transparent;
}

/* Typography */

.MuiTypography-body1 {
  color: $body1;
  font-weight: bold;
  font-size: 18px;
}

.MuiTypography-h1 {
  color: $h1;
  font-size: 25px;
}

.MuiTypography-h2 {
  color: $h2;
  font-weight: bold;
  font-size: 30px;
}

.MuiTypography-h3 {
  color: $h3;
  font-size: 30px;
  padding-left: 25px;
}

.MuiTypography-h4 {
  color: $h4;
  font-size: 22px;
}

.MuiTypography-h5 {
  color: $h5;
  font-size: 22px;
}

/* Page Stamp */
.pageStamp {
  color: $grey;
  font-size: 24px;
}

/*Accent*/
.accent {
  color:$accent;
}

/* Cards */
.basicCardContainer {
   height: 100px;
    margin: 0 auto;

}

.basicCardContainer .MuiCard-root {
  background: linear-gradient(to right, #ffffff, #faf8f8);
  height: 100px;
  width: 100%;
  color:#aafbf2;
  border-radius: 10px;
}

.basicCardContainer  img{
  height: 90px;
  margin-top: 5px;
  border-radius: 10px;
  

}

.settingCardContainer {
  height: 900px;
}
.settingCardContainer h5{
  color: #fff;
}

.settingCardContainer .MuiCard-root {
  
  background: linear-gradient(to bottom, #006bb6, #6ab1e3);
}

/* Alignment */
.marginCenter {
  margin: 0 auto;
}

.centerAlign {
  text-align: center;
}

.rightAlign {
  float: right;
}

.leftAlign {
  float: left;
}

/* App Container */

.appCardContainer {
  margin: 0 auto;
}

.appCardContainer a{
  text-decoration: none;
  color: #fff;
}



.adminCardContainer {
  margin: 0 auto;
  width: 100%;

}

.settingCardContainer  .content {
  background: linear-gradient(to bottom, $dashboardBackgroundColor, $dashboardCardGradientBackgroundColor);
  clip-path: ellipse(165% 86% at 75%  0% );
  height: 275px;
  
}

.settingCardContainer .MuiCard-root {
  background: #fff;
}

.settingCardContainer .MuiTypography-body1 {
  color: $cardFontColor;
}

.settingCardContainer .settingCard {
  background: #004779;
}

.settingCardContainer a {
  text-decoration: none;
}

.imageCardContainer {
    margin: 0 auto;
    width: 100%;

}

.modelRegistryCardContainer {
  margin: 0 auto;
  font-size: 22px;
}

.modelRegistryCardContainer .MuiCard-root{
  background: linear-gradient(to bottom, #ffffff, #e5e9f8);
}

/* Alignment */
.rightAlign {
    text-align: right;
    margin-top: 17px;
    padding-right: 30px;
}

/* Typography */
.pageTitle {
    font-size: 20px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; 
    color: $pageTitle;
    font-style: italic;
    text-align: left;
}

.pageDescription {
    font-size: 20px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; 
    color: $primary;
    font-style: italic;
    text-align: center;  
}

/* Divicer */

.divider {
    float: left;
    width: 95%;
    height: 1px;
    
    background-image: linear-gradient(
      to right,
      transparent,
      $primary,
      transparent
    );
  }

  .cardDivider {

    width: 100%;
    height: 3px;
    background-image: linear-gradient(
      to right,
      transparent,
      #a4b2f0,
      transparent
    );
  }

  .dividerDotted {
    margin: $dividerShapeMargin;
    width: 85%;
    border: 2px dashed #98a6e2;
    opacity: 0.1;
  }

  // Pagination

//curved background
.pagination {
    margin: 10px auto;
    background: #dbdfff;
    padding: 10px;
    border-radius: 7px 7px 0 0;
    width: 60%;
  }
  
  
  //list items
  .pagination > li {
    display: inline-block;
    padding-left: 12px;
    text-align: center;
    font-size: large;
    justify-content: center;
    font-family: monospace;
    list-style: none;
    margin-left: 5px;
    color: #525dc2;
    }
  
  
  
  .active {
    color: #8a9ffd;
    font-weight: bold;
    font-family: monospace;
  }

  /* Banner Fade */
  .bannerFade {
    background: linear-gradient(to right, #6384f2, #ffffff);
    
    padding: 5px;
    margin-left: -15px;
  }

  /* Tables */
  .table {
    background: #ffffff;
    color: $primary;
    border-radius: 10px;
  }

  .tableHead {
    background: $primary;
  }

 

  /* Text Field */
  .inputField {
    background: #fff;
    border-radius: 10px;
    width: 100%;
  }


  /* Links */
  .links {
    color: #bbc8ff;
    font-size: 20px;
    float: right;
 
  }

  .links a {
    text-decoration: none;
    color: #7b92f9;
    font-size: 20px;
  
  }

  .links a:hover {
    text-decoration: none;
    color: #e9edff;

    width: 100%;
    font-size: 20px;

  }
  .menuItem a {
    width: 100%;
    padding: 5px;
    color:  $primary;
    text-decoration: none;
    font-size: 21px;
  }



  .menuItem a:hover {
    width: 100%;
    border-radius: 5px;
    background: $primary;
    padding: 5px;
    color: #fff;
  }




  