@import "./variables.scss";


body {
  background: $backgroundColor;
  margin: $appContainerBodyMargin;

}


.appContainer {
    height: $appContainerHeight;

  }
  
  /* Header Container */
  .headerContainer {
    height: $headerContainerHeight;
    padding-top: $headerContainerPaddingTop;
    padding-left: $headerContainerPaddingLeft;
    background: $headerContainerBackgroundColor;
  }
  
  .headerContainer img {
    margin-top: 12px;
    padding-top: $headerContainerImgPaddingTop;
    margin-left: 10px;
    height: $headerContainerImgHeight;
    width: $headerContainerImgWidth;
    float: $headerContainerImgFloat;
  }

  /* Title Container */
  .titleContainer {
    padding-left: 25px;
    margin-top: 25px;
    
  }

  .titleContainer .MuiTypography-body2 {
   color: #b6b6b6;
  }
  

  .titleContainer h6 {
    font-size: 18px;
    color: $primary;
    font-style: italic;
  }

  /* Footer */

  .footerContainer{

    background: $footerBackground;
    padding-bottom: 25px;
  }

  .footerContainer img{
    height: 50px;
    padding-left: 15px;
    margin-top: -10px;
  }

  .footerContainer .MuiTypography-body2
  {
    margin: 30px 0 0 0;
    color: #b9b9b9;
    font-size: 18px;
  }



  /* Template Main Container */
  .contentContainer {
    height: 100%;
    // background: #f8f8f8;

  }

  /* Dashboard Container */
  
  .dashboardContainer {
    margin: 30px auto;
    width: 100%;
    height: 100%;
    min-height: 75vh;
    border-radius: 10px;
 
  }

  .dashboardContainer a {
    text-decoration: none;
  }

  .dashboardContainer .content {
    margin: 0 auto;
  }

  .dashboardContainer .MuiTypography-h5 {
    color: #F6FCFF;
    font-size: 18px;
  }

  .dashboardContainer img{
    height: 200px;
  }


  .dashboardImageCardContainer {
    background: $dashboardBackgroundColor;
    height: 275px;
    width: 100%;
  }

  .dashboardImageCardContainer  .content {
    background: $dashboardBackgroundColor;
    clip-path: ellipse(165% 80% at 75%  0% );
    height: 275px;
    width: 100%;
  }

  .dashboardCardContainer {
    margin: 0 auto;
    width: 100%;
    height: 300px;
  }

  .dashboardCardContainer .MuiTypography-h5 {
    color: $cardFontColor;
    font-size: 22px;
  }

  .dashboardCardContainer  .content {
    background: linear-gradient(to bottom, $dashboardBackgroundColor, $dashboardCardGradientBackgroundColor);
    clip-path: ellipse(165% 86% at 75%  0% );
    height: 275px;
  }

  .dashboardCardContainer  .MuiCard-root{
  background:  $white;
  }

  /* Admin Container */
  .adminContainer {
    margin: 30px auto;
    width: 100%;
    height: 100%;
    min-height: 75vh;
    border-radius: 10px;
  }

  .adminList {
    color: #1d2960;
    border-radius: 15px;
    height: 150px;
  }


  .adminList .MuiAvatar-root{
    width: 75px;
    height: 75px;
    margin: 0 auto;
    text-align: center;
  }

  .adminList .content {
    margin-top:50px;
  }

   /* Tools Container */
   .toolsPageContainer {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    border-radius: 10px;

  }


  .toolsPageContainer h5 {
    color: $primary;
    text-transform: uppercase;
  }


  .toolsPageContainer .MuiCard-root{
    height: 350px;
    font-size: 18px;
    color: #333;
    border-radius: 10px;
  }

  /* NLP Search */
  .nlpSearchContainer {
    height: 100%;
    min-height: 75vh;
  }

 
  /* Workflow Container */
  .workflowContainer {
    margin: 50px auto;
  
  }

  /* Data Catalog Cotainer */
  .dataCatalogContainer {
    height: 100%;
    min-height: 75vh;
    margin: 15px auto;
  }


  .dataCatalogContainer .MuiButton-root {

    background: $primary;
    color: #fff;
    padding: 20px;
  }


  .dataCatalogContainer .MuiChip-root {

    border: 1px solid $primary;
    color: $primary;
    padding: 20px;
  }

  .dataCatalogContainer .searchBox {
    background: $searchBoxBackgroundColor;
    border-radius: 10px;
    border-bottom: #CCC 2px solid;
  }

  .dataCatalogContainer .searchBox .searchButton {
    background: $primary;
    color: #ffffff;
    margin-top: 0px;
    margin-right: 10px;
    float: right;
  }



  /* Model Registry */
  .modelRegistryContainer {
    height: 100%;
    min-height: 75vh;
  }
 

   /* Natural Language Cotainer */
   .natLangSearchContainer {
    margin: 50px auto;
 
    }

    .natLangSearchContainer .searchBox {
      background: $searchBoxBackgroundColor;
      border-radius: 10px;
      margin: 0 auto;
    
    }

    .natLangSearchContainer .searchBox .searchButton {
      background: $primary;
      color: #aafbf2;
      margin-top: 5px;
      margin-right: 10px;
      float: right;
    }

    /* NLP Upload */
    .nlpUploadContainer {
      margin: 50px auto;
   
    }

    /* Data Upload Contaier */
    .dataUploadContainer {
      height: 100%;
      min-height: 75vh;
    }


    .dataUploadContainer .submit {
      float: right;
      display: flex;
      justify-content: center;
      width: 250px;
    }

    .dragDropArea {
      background: linear-gradient(to bottom, #01518B, #006BB6);
      height: 400px;
   
    }
    .formListTextbox {
    
      background-color: #ffffff;
      border-radius: 5px;
    }

    .formListTypography {
      color: $primary;
      margin-top: 15px;
      float: left;
      padding-bottom: 10px;
    }

    .message {
      background: linear-gradient(to bottom, #ccd7ff, #a9b9fe);
      color: #181d35;
      margin: 0 auto;
      text-align: left;
      font-size: 18px;
      border-radius: 20px;
      padding:  20px;
      width: 85%;

    }


    /* List */ 
    .listContainer {
      background: linear-gradient(to right, #202e63, $primary);
      border-radius: 5px;
    
    }

 
    /* Governance */
    .governanceContainer {
      height: 100%;
      min-height: 75vh;
    }

  

    .governanceSection {
      background: linear-gradient(to bottom, #ffffff, #ffffff);
      padding-bottom: 25px;
      padding-left: 10px;
      color: $primary;
      text-align: center;
      border-radius: 10px;
    }

    .governanceSection .MuiListItem-root {
      text-align: center;
    }



    /* Tools */
    .toolsContainer {
      height: 75vh;
      margin: 0 auto;
      color: #006BB6;
    }
    
    /* Search Card */
    .searchCardContainer {
      background-color: #fff;
      border-bottom: $primary solid 5px;
      flex-direction: column;
      border-radius:10px;
      padding: 16px;
      transition: transform 0.35s ease-in-out;
      height: 100%;
  
      color: $primary;
    }

    .searchCardContainer h1 {
      font-size:24px;
      padding-bottom: 10px;
      
   

    }

    .searchCardBody {
      font-size: 16px;
      color: #6b6b6b;
      line-height: 20px;
      flex-grow: 1;
    }
    
    .searchCardButton {
      text-transform: uppercase;
      color: #FFF;
      border: 1px solid #FFF;
      border-radius: 4px;
      margin-top: 15px;
    }
    
    /* Brick and Training Section */
    .BrickAndTrainingSectionHeader {
      font-size: 21px;
      font-weight: 400;
    }

  
    /* Tools Section */
    .toolsGridContainer {
      background: linear-gradient(to right, #ffffff, #ffffff);
      box-shadow: #d5d5d5 0px 3px 1px 1px;
      border-radius: 10px;
      padding: 16px;
    }

    .toolsList {
         font-size: 20px;
 
    }

    .linkButton {
      border-radius: 10px;
      padding: 10px;
      background: #f0f0f0;
      color: $primary;
      &:hover {
        color: #fff;
        background:#006bb6;
      }
    }
    
 