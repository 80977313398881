@import "./theme.scss";

/* Sizing */
$desktopResWidth: 800px;
$tabletResWidth: 700px;
$mobileResWidth: 600px;

/* Pallette */
$backgroundColor: var(--app-backgroundColor);
$primary: var(--app-primary);
$cardFontColor: var(--app-cardFontColor);
$fontColor: var(--app-fontColor);
$h1: var(--app-h1);
$h2: var(--app-h2);
$h3: var(--app-h3);
$h4: var(--app-h4);
$h5: var(--app-h5);
$pageTitle: var(--app-pageTitle);
$body1 : var(--app-body1);

$black: #000000;
$white: #ffffff;
$grey: #c9c9c9;
$accent: #81caff;


//Div Spacing
$spacingWidth: 100%;
$spacingHeight: 20px;

//Margin
$marginCenter: 0 auto;
$marginLeft: 15px;


/* Type Face */
$titleFont: Inter;
$tableFont: Rift;
@import url("https://fonts.google.com/specimen/Inter");
@import url("https://fonts.adobe.com/fonts/rift");

/* Containers */
/* App Container */
$appContainerHeight: 100%;
$appContainerBodyMargin: 0;

/* Header Container */
$headerContainerHeight: 75px;
$headerContainerPaddingTop: 1px;
$headerContainerPaddingLeft: 10px;
$headerContainerBackgroundColor: linear-gradient(to right, var(--app-headerBackgroundColor), var(--app-headerBackgroundColor));
$headerContainerImgMarginTop: 15px;
$headerContainerImgMarginLeft: 55px;
$headerContainerImgWidth: auto;
$headerContainerImgHeight: 40px;
$headerContainerImgPaddingTop: 5px;
$headerContainerImgFloat: left;

/* Content Container */
$contentContainerBckGrd: transparent;
$contentContainerHeight: 100%;
$contentContainerPadding: 18px;
$contentContainerMargin: 0 auto;
$contentContainerPaddingTop: 3px;

/* Dashboard Container */ 
$dashboardBackgroundColor: var(--app-primary);
$dashboardCardGradientBackgroundColor: var(--app-gradientBackgroundColor);

// Footer Container
$footerBackground: linear-gradient(to right, var(--app-footerBackgroundColor), var(--app-footerBackgroundColor));
$footerHeight: 150px;

/* Menu Container */
$menuIconButtonColor: $white;
$menuContainerFontSize: 17px;
$menuContainerTextDecoration: none;
$menuContainerTextAlign: center;
$menuContainerHoverBackground: #a0a0a0;

/* Divider */
$dividerShapeMargin: 0 auto;
$dividerShapeWidth: 95%;
$dividerShapeHeight: 15px;
$dividerShapeSkew: skew(-5deg);
$dividerShapeBckgrdImg: linear-gradient(to right, $primary, #0b80d4);
$dividerShapeHeight: 0.5px;

/* Search Page */
$searchPageContainerMargin: 5px auto;
$searchPageContainerHeight: 100%;
$searchPageContainerPaddingBottom: 175px;
$searchBodyMargin: 0 auto;
$searchBodyHeight: 100%;
$searchBodyPaddingBottom: 75px;
$searchBoxBackgroundColor: linear-gradient(to right, #ffffff, #ffffff);

/* Search */
$searchIconWidth: 100%;
$searchIconHeight: 100%;
$searchMainDisplay: flex;
$searchMainJustifyContent: center;
$searchMainAlignItems: center;
$searchBoxBorderRadius: 7px;
$searchBoxShadow: 1px 3.5px 0px rgba(177, 177, 177, 0.4);

/* Search Button */
$searchButtonDisplay: flex;
$searchButtonJustinifyContent: center;
$searchButtonMarginTop: 5px;
$searchButtonBackgroundColor: $accent;
$searchButtonMarginRight: 25px;
$searchButtonHeight: 40px;

/* Table */
$tableBodyTextAlign: left;
$tableBodyBackgroundHover: #ebf5fd;
$tableHeadFontStyle: bold;
$tableHeadBackground: #016bb5;
$tableHeadColor: $white;

/* Alignment */
$centerAlignText: center;
$rightAlignPaddingRight: 35px;
$rightAlignText: right;
